.NavigationBar {
  background-color: #101010;
}

.NavigationBar-LogoBox {
  background-color: #202020;
  border-color: #eeeeee;
  border-radius: 50px;
  border-style: solid;
  border-width: 3px;
  height: min-content;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  width: min-content;
}

.NavigationBar-Logo {
  display: block;
  height: 15vmin;
  padding: 15px;
}

.NavigationBar-Options {
  background-color: #202020;
}

.NavigationBar-Options a {
  color: #eeeeee;
  display: inline-block;
  margin: 0px;
  padding: 10px;
  text-align: center;
  text-decoration: none;
}

.NavigationBar-Options a:hover {
  color: #cccccc;
}

.NavigationBar-Dropdown {
  display: inline-block;
}

.NavigationBar-Dropdown:hover .NavigationBar-Dropdown-Content {
  display: block;
}

.NavigationBar-Dropdown-Button {
  background-color: inherit;
  border: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  outline: none;
}

.NavigationBar-Dropdown-Content {
  background-color: #303030;
  display: none;
  position: absolute;
  z-index: 1;
}

.NavigationBar-Dropdown-Content a {
  display: block;
  text-decoration: none;
}

.NavigationBar-Dropdown-Content a:hover {
  color: #cccccc;
}