.Home {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 30px;
}

.Home-Content {
  background-color: #e0e0e0;
  color: #e0e0e0;
  display: flex;
  flex-direction: column;
  margin: 0px;
  text-decoration: none;
  width: 30vmin;
}

.Home-ContentImage {
  margin: 3px;
}

.Home-ContentCaption {
  background-color: #0A3161;
  text-decoration: none;
  margin: 3px;
  margin-top: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}