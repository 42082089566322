.Error404 h1 {
  color: rgb(180, 60, 60);
  font-size: 3vw;
  margin: 30px;
}

.Error404 p {
  font-size: 1vw;
  margin-bottom: 10px;
}

.Error404 a {
  color: #e0e0e0;
}