.Post {
  align-self: center;
  background-color: #303030;
  border-radius: 25px;
  border-style: solid;
  border-width: 3px;
  font-size: 20px;
  justify-content: center;
  margin: 25px;
  max-width: 70vmax;
  padding: 15px;
  width: 90vw;
}

.Post h1 {
  font-size: 50px;
  margin: 0px;
  margin-top: 5px;
}

.Post h2 {
  font-size: 20px;
}

.Post p {
  font-size: 16px;
  text-align: left;
  margin: 20px;
}

.Post-Date {
  margin: 0px;
  text-align: center;
}