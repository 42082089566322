.Layout {
  background-color: #404040;
  color: #e0e0e0;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
}

.Layout-Wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-height: calc(100% - 10px);
}