.Footer {
  margin-top: 10px;
  margin-bottom: 5px;
}

.Footer-Logos {
  align-items: center;
  display: flex;
  justify-content: center;
}

.Footer-Logos a img {
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  width: 6vmin;
}

.Footer p {
  margin: 0px;
}

.Footer p a {
  color: #e0e0e0;
}