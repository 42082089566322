.HailToTheChief {
  align-items: center;
  background-color: #0A3161;
  border-style: solid;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.HailToTheChief-TitleBar {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 30px;
  max-width: min(1000px, 90vw);
}

@media (max-width: 1000px) {
  .HailToTheChief-TitleBar {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: min(1000px, 90vw);
  }
}

.HailToTheChief-TitleBar img {
  margin: 0px;
  max-width: calc(min(90vmin, 300px));
}

.HailToTheChief-TitleBar-Content {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 20px;
  max-width: min(1000px, 90vw);
}

.HailToTheChief-TitleBar-Content h1 {
  font-size: 40px;
  margin: 0px;
}

.HailToTheChief-TitleBar-Content p {
  font-size: large;
}

.HailToTheChief-TitleBar-Content-Buttons {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  margin: 0px;
  max-width: min(1000px, 90vw);
}

@media (max-width: 1000px) {
  .HailToTheChief-TitleBar-Content-Buttons {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    margin: 0px;
    max-width: min(1000px, 90vw);
  }
}

.HailToTheChief-TitleBar-Content-Buttons-Activated {
  align-items: center;
  background-color: #B31942;
  border-color: #e0e0e0;
  border-style: solid;
  color: #e0e0e0;
  flex: 1;
  justify-content: center;
  margin: 10px;
  padding: 15px;
}

.HailToTheChief-TitleBar-Content-Buttons-Deactivated {
  align-items: center;
  background-color: #4b091a;
  border-color: #c0c0c0;
  border-style: solid;
  color: #c0c0c0;
  flex: 1;
  justify-content: center;
  margin: 10px;
  padding: 15px;
}

.HailToTheChief video {
  align-items: center;
  margin-bottom: 30px;
  max-width: min(1000px, 90vw);
}

.HailToTheChief-Features {
  display: flex;
  flex-direction: column;
  max-width: min(1000px, 90vw);
}

.HailToTheChief-Features h1 {
  font-size: 28pt;
  margin: 0px;
}

.HailToTheChief-Features p {
  margin-bottom: 0px;
}

.HailToTheChief-Features ul {
  align-self: center;
  width: fit-content;
}

.HailToTheChief-Features li {
  margin-bottom: 10px;
  text-align: left;
}