.DeveloperLog-Grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    justify-content: center;
    padding-bottom: 5px;
    padding-top: 5px;
    width: 100%;
}

.DeveloperLog-GridItem {
    background-color: #303030;
    border-radius: 0;
    border-style: solid;
    border-width: 3px;
    color: #e0e0e0;
    display: grid;
    font-size: 20px;
    margin: 5px;
    padding: 15px;
    text-decoration: none;
}

.DeveloperLog-GridItem h1 {
    margin: 0px;
  }

.DeveloperLog-GridItem p {
    margin: 0px;
  }