.MailingList {
  align-self: center;
  background-color: #303030;
  border-radius: 25px;
  border-style: solid;
  border-width: 3px;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  justify-content: center;
  margin: 25px;
  padding: 15px;
}

.MailingList p {
  margin: 0px;
}

.MailingList ul {
  align-self: center;
  margin: 10px;
  width: fit-content;
}

.MailingList li {
  text-align: left;
}

.MailingList input {
  background-color: #e0e0e0;
  border-color: #101010;
  border-style: solid;
  font-family: inherit;
  font-size: 20px;
  margin-top: 10px;
  max-width: 70vw;
  width: 40ch;
}

.MailingList button {
  background-color: #606060;
  border-color: #101010;
  border-style: solid;
  color: #e0e0e0;
  font-family: inherit;
  font-size: 20px;
  margin-left: 10px;
}