.About {
  align-self: center;
  background-color: #303030;
  border-radius: 25px;
  border-style: solid;
  border-width: 3px;
  font-size: 20px;
  justify-content: center;
  margin: 25px;
  padding: 15px;
  width: 80vmin;
}

.About p {
  margin: 0px;
}